import React from "react";
import { RichText } from "prismic-reactjs";

import Props from "./types";

import Section from "../../section/Section";
import Button from "../../button/Button";
import SubscriptionOption from "../../subscriptionOption/SubscriptionOption";
import { useUser } from "../../../context/UserContext";
import { useShopContext } from "../../../context/ShopContext";
import InternalLink from "../../InternalLink";

import styles from "./subscriptionInfo.module.css";

const SwitchSubscription = ({ slice }: Props): JSX.Element => {
  const { userProfile } = useUser();
  const { subscriptionOption } = useShopContext();

  const doc = slice?.primary;
  const title = doc.title;
  const subtitle = doc.subtitle;
  const bodyText = doc.bodyText;
  const buttonText = doc.buttonText;
  const subscriptionOptions = slice.items;

  return (
    <Section bgColor="var(--colorWhite)">
      <div className={styles.columnContainer}>
        <div className={styles.titleSwitchSubscription}>
          <RichText render={title} />
        </div>
        <div className={styles.rowContainer}>
          <div className={styles.subscriptionContainer}>
            <div className={styles.contentContainer}>
              <div className={styles.subTitle}>
                <div>
                  {subscriptionOption
                    ? `Currently selected - ${subscriptionOption.plan}`
                    : "Currently selected - No plan"}
                </div>
              </div>
              <div className={styles.bodyText}>
                {subscriptionOption && (
                  <>
                    <div>
                      {subscriptionOption.limit} pieces each month / pick your
                      own
                    </div>
                    <div className={styles.boldText}>
                      {subscriptionOption.price} a month
                    </div>
                  </>
                )}
              </div>
            </div>
            <InternalLink href={userProfile ? "/account" : "/collection"}>
              <a className={styles.button}>
                <Button text={buttonText} />
              </a>
            </InternalLink>
          </div>
          <div className={styles.subscriptionContainer}>
            {subscriptionOptions.map((item, index) => {
              return (
                <SubscriptionOption
                  key={index}
                  letter={item.subscriptionOption}
                  title={item.optionTitle}
                  description={item.optionBody}
                  price={item.optionPrice}
                  limit={item.optionLimit}
                  stripeId={item.stripeId}
                />
              );
            })}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default SwitchSubscription;
