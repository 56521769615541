import React from "react";
import { RichText } from "prismic-reactjs";

import Props from "./types";
import Section from "../../section/Section";
import TermsAccordion from "../../accordion/TermsAccordion";

import styles from "./terms.module.css";

const TermsAndConditionsSlice = ({ slice }: Props): JSX.Element => {
  const title = slice?.primary.titleCenter[0].text;
  const content = slice?.items;

  return (
    <>
      <div className={styles.title}>{title}</div>
      <Section minHeight="500px">
        <div className={styles.termsContainer}>
          <div className={styles.contentContainer}>
            {content.map((item, index) => {
              if (!item.Subtitle[0]) {
                return <></>;
              }
              return (
                <TermsAccordion
                  key={`${item}+${index}`}
                  title={item.Subtitle && item.Subtitle[0].text}
                >
                  <RichText render={item.Content} />
                </TermsAccordion>
              );
            })}
          </div>
        </div>
      </Section>
    </>
  );
};

export default TermsAndConditionsSlice;
