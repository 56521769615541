import { Ref, MutableRefObject, RefObject, useState } from "react";
import { FormikProps } from "formik";

import SimpleInput from "../input/SimpleInput";
import Button from "../button/Button";
import FORMS from "../constants/forms";

import styles from "./giftAmountForm.module.css";

interface Props {
  handleCheckout: ({ giftAmount }: { giftAmount: string }) => void;
  buttonText: string;
  onInputFocus: () => void;
  formSubmitRef?:
    | MutableRefObject<FormikProps<object>>
    | Ref<FormikProps<object>>
    | RefObject<FormikProps<object>>;
}

const GiftAmountForm: React.FC<Props> = ({
  handleCheckout,
  buttonText,
  onInputFocus,
  formSubmitRef,
}) => {
  const [error, setError] = useState(null);

  const formData = {
    ...FORMS.GIFTAMOUNT,
    handleSubmit: handleCheckout,
    innerRef: formSubmitRef,
  };

  return (
    <>
      <SimpleInput data={formData} onFocus={onInputFocus} />
      {error && <div className={styles.error}>{error}</div>}
    </>
  );
};

export default GiftAmountForm;
