import React from "react";
import Image from "next/image";
import classNames from "classnames";

import Props from "./types";
import Button from "../../components/button/Button";
import customImageLoader from "../../utils/imageloader";

import styles from "./subscriptionOptionGridCard.module.css";

const SubscriptionOptionGridCard = ({
  letter,
  title,
  description,
  price,
  image,
  buttonText,
  link,
  limit,
  stripeId,
  onSelect,
  active,
  gifting,
}: Props): JSX.Element => {
  return (
    <div
      className={classNames(styles.container, {
        [styles.activeContainer]: active,
      })}
    >
      <div className={styles.image}>
        <Image
          src={image.url}
          height={image.dimensions.height}
          width={image.dimensions.width}
          layout="intrinsic"
          loading="eager"
          alt="image of child"
          loader={customImageLoader}
        />
      </div>
      <div className={styles.flexContainerColumn}>
        <div className={styles.textContainer}>
          <div className={styles.option}>{letter}</div>
          <div className={styles.title}>{title}</div>
          <div className={styles.text}>
            {description.split("\n").map((txt) => (
              <div key={txt}>{txt}</div>
            ))}
          </div>
          {!gifting && <div className={styles.textBold}>{price}</div>}
        </div>
        <div className={styles.button}>
          <Button
            onClick={() =>
              onSelect({
                plan: letter,
                limit: limit,
                price: price,
                stripeId: stripeId,
              })
            }
            text={buttonText}
          />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionOptionGridCard;
