import React from "react";
import Image from "next/image";
import classNames from "classnames";

import Props from "./types";
import Section from "../../section/Section";
import PostcodeForm from "../../postcodeForm/PostcodeForm";
import customImageLoader from "../../../utils/imageloader";
import { useAppContext } from "../../../context/AppContext";

import styles from "./delivery.module.css";

const DeliverySlice = ({ slice }: Props): JSX.Element => {
  const { pauseNewMembers } = useAppContext();
  const image = slice?.primary.image;
  const title = slice?.primary.title[0].text;
  const bodyText = slice?.primary.bodyText[0].text;
  const buttonText = slice?.primary.buttonText;

  const titlePaused = slice?.primary.titlePauseNewMembs[0].text;
  const bodyTextPaused = slice?.primary.bodyTextPauseNewMembs[0].text;
  const buttonTextPaused = slice?.primary.buttonTextPauseNewMembs;

  const selectedTitle = pauseNewMembers ? titlePaused : title;
  const selectedBodyText = pauseNewMembers ? bodyTextPaused : bodyText;
  const selectedButtonText = pauseNewMembers ? buttonTextPaused : buttonText;

  return (
    <Section bgColor="var(--colorWhite)" columnReverse={true}>
      <div className={styles.container}>
        <div className={styles.title}>{selectedTitle}</div>
        <div className={styles.deliveryContainer}>
          <div className={styles.formInfo}>
            <div className={styles.bodyText}>{selectedBodyText}</div>
            <div className={styles.inputContainer}>
              <PostcodeForm
                newsletter
                waitlist={pauseNewMembers ? true : false}
                buttonText={selectedButtonText}
                buttonClassName={styles.button}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames(
          styles.deliveryContainer,
          styles.deliveryContainerImage
        )}
      >
        <div className={styles.image}>
          <Image
            width={image.dimensions.width}
            height={image.dimensions.height}
            src={image.url}
            alt="Display image"
            loading="eager"
            layout="intrinsic"
            loader={customImageLoader}
          ></Image>
        </div>
      </div>
    </Section>
  );
};

export default DeliverySlice;
