import React from "react";
import Image from "next/image";

import { RichText } from "prismic-reactjs";

import Props from "./types";
import InternalLink from "../../InternalLink";
import Button from "../../button/Button";
import Section from "../../section/Section";
import customImageLoader from "../../../utils/imageloader";

import styles from "./styleValuesBreakdown.module.css";

const StyleValuesBreakdownSlice = ({ slice }: Props): JSX.Element => {
  const title = slice?.primary.title[0].text;
  const buttonText = slice?.primary.buttonText;
  const buttonLink = slice?.primary.buttonLink.slug;
  const items = slice.items;

  return (
    <Section bgColor="var(--colorWestar)">
      <div className={styles.styleValuesBreakdownContainer}>
        <div className={styles.title}>{title}</div>
        <div className={styles.contentContainer}>
          {items.map((item, index) => {
            return (
              <div className={styles.itemContainer} key={index}>
                <div className={styles.valuesItem}>
                  <div>
                    <Image
                      className={styles.image}
                      src={item.icon.url}
                      height={80}
                      width={80}
                      alt="Display image"
                      layout="intrinsic"
                      loading="eager"
                      loader={customImageLoader}
                    />
                  </div>
                  <div className={styles.itemTitle}>{item.title}</div>
                  <div className={styles.itemBodyText}>
                    {RichText.render(item.bodyText)}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles.button}>
          <InternalLink href={buttonLink}>
            <a>
              <Button text={buttonText} />
            </a>
          </InternalLink>
        </div>
      </div>
    </Section>
  );
};

export default StyleValuesBreakdownSlice;
