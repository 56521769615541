import React, { useContext, useState, useEffect } from "react";
import { useRouter } from "next/router";

import { Props, Postcode } from "./types";
import Button from "../button/Button";
import SimpleInput from "../input/SimpleInput";
import FORMS from "../constants/forms";
import { ModalContext } from "../../context/ModalContext";
import { useAppContext } from "../../context/AppContext";
import PostcodePopup from "../postcodePopup/PostcodePopup";
import { addSubscriber } from "../../modules/@klaviyo";
import { KlaviyoListCodes } from "../../commonTypes";

//** - add 'waitlist' as well as 'newsletter' props to make the email go to the waitlist

const PostcodeForm = ({
  buttonText,
  buttonClassName,
  square,
  formSubmitRef,
  newsletter,
  waitlist,
}: Props): JSX.Element => {
  const [success, setSuccess] = useState(null);

  const router = useRouter();
  const { handleModal } = useContext(ModalContext);

  // return <></>;
  const { postcodes, popupImage } = useAppContext();

  const handleSubmit = ({ postcode }: Postcode): void => {
    //checks if postcode is included in the accepted list of postcodes (from context)

    postcode = postcode.toLowerCase();

    //@ts-ignore
    if (typeof gtag !== "undefined") {
      //@ts-ignore
      gtag("event", "postcode_submitted", {
        event_category: postcode,
        event_label: postcode,
      });
    }

    const postcodeNoWhitespace = postcode.replaceAll(" ", "");
    const splitPostcode = postcodeNoWhitespace.slice(
      0,
      postcodeNoWhitespace.length - 3
    );
    const accepted = !!postcodes.includes(splitPostcode);

    //if accepted, stores true value in localstorage
    if (accepted && typeof window !== "undefined") {
      // localStorage.setItem("postcodeAccepted", "true");
      localStorage.setItem("postcode", postcode);
    }

    const content = (
      <PostcodePopup accepted={accepted} popupImage={popupImage} />
    );

    handleModal({ content: content, withImage: true });
  };

  const handleSubmitNewsletter = (values) => {
    addSubscriber(values.email, {
      ...values,
      list: !waitlist ? KlaviyoListCodes.NEWSLETTER : KlaviyoListCodes.WAITLIST,
    }).then(() => {
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 3200);
    });
  };

  const SubmitButton = () => {
    return (
      <div className={buttonClassName}>
        <Button type="submit" text={buttonText} />
      </div>
    );
  };

  const SubmitButtonNewsletter = () => {
    return (
      <div>
        <Button
          disabled={success}
          type="submit"
          text={success ? "Email subscribed!" : buttonText}
        />
      </div>
    );
  };

  const formData = {
    ...FORMS.POSTCODE,
    handleSubmit: handleSubmit,
    submitButton: SubmitButton,
  };

  const formDataNoButton = {
    ...FORMS.POSTCODE,
    handleSubmit: handleSubmit,
    innerRef: formSubmitRef,
  };

  const newsletterData = {
    ...FORMS.NEWSLETTER,
    handleSubmit: handleSubmitNewsletter,
    innerRef: formSubmitRef,
    submitButton: SubmitButtonNewsletter,
  };

  return (
    <SimpleInput
      data={
        newsletter ? newsletterData : buttonText ? formData : formDataNoButton
      }
      inputStyle={square ? "square" : "round"}
    />
  );
};

export default PostcodeForm;

//localStorage.setItem("postcode", );
