import React, { useState } from "react";
import classNames from "classnames";

import ICONS from "../constants/icons";
import { AccordionProps } from "./types";

import styles from "./termsAccordion.module.css";

const TermsAccordion = ({ title, children }: AccordionProps) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      className={classNames(styles.dropdown, {
        [styles.dropdownOpen]: open,
      })}
    >
      <div
        className={styles.title}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <ICONS.PLUS size={18} />
        {title}
      </div>
      {open && <div className={styles.dropdownInfo}>{children}</div>}
    </div>
  );
};

export default TermsAccordion;
