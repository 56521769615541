import React from "react";
import Image from "next/image";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from "pure-react-carousel";

import Props from "./types";
import Button from "../../components/button/Button";
import InternalLink from "../../components/InternalLink";
import customImageLoader from "../../utils/imageloader";

import styles from "./styledForYouGridCard.module.css";

const StyledForYouGridCard = ({
  letter,
  title,
  description,
  price,
  image,
  buttonText,
  link,
}: Props): JSX.Element => {
  const imageArray = [];

  image.forEach((item) => {
    if (item.url) {
      imageArray.push(item);
    }
  });

  return (
    <div className={styles.container}>
      <div className={styles.image}>
        {image.length > 1 && (
          <CarouselProvider
            naturalSlideWidth={1}
            naturalSlideHeight={1}
            totalSlides={imageArray.length}
          >
            <Slider>
              {imageArray.map((item, index) => {
                return (
                  <Slide
                    innerClassName={styles.slide}
                    key={index}
                    index={index}
                  >
                    <div className={styles.imageContainer}>
                      <Image
                        src={item.url}
                        height={item?.dimensions?.height}
                        width={item?.dimensions?.width}
                        alt="Display image"
                        layout="intrinsic"
                        loading="eager"
                        loader={customImageLoader}
                      />
                    </div>
                  </Slide>
                );
              })}
            </Slider>
            <DotGroup className={styles.dots} />
          </CarouselProvider>
        )}
        {image.length === 1 && (
          <Image
            src={image[0].url}
            height={image[0].dimensions.height}
            width={image[0].dimensions.width}
            layout="intrinsic"
            alt="image of child"
            loading="eager"
            loader={customImageLoader}
          />
        )}
      </div>
      <div className={styles.flexContainerColumn}>
        <div className={styles.textContainer}>
          <div className={styles.option}>{letter}</div>
          <div className={styles.title}>{title}</div>
          <div className={styles.text}>{description}</div>
          <div className={styles.textBold}>{price}</div>
        </div>
        <div className={styles.button}>
          <InternalLink href={link ? link : "/"}>
            <a>
              <Button text={buttonText} />
            </a>
          </InternalLink>
        </div>
      </div>
    </div>
  );
};

export default StyledForYouGridCard;
