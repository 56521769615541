import React from "react";
import classNames from "classnames";

import Props from "./types";

import styles from "./section.module.css";

const PageSection = ({ children }: Props): JSX.Element => {
  return (
    <div className={styles.bgContainer}>
      <div className={styles.pageSection}>{children}</div>
    </div>
  );
};

export default PageSection;
