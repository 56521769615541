/* eslint-disable @next/next/no-img-element */
import React from "react";
import Link from "next/link";

import Props from "./types";

import styles from "./heroCarousel.module.css";

const HeroCarousel = ({ children, slice }: Props): JSX.Element => {
  const images = slice?.items;

  return (
    <div className={styles.carouselContainer}>
      <div className={styles.contentContainer}>
        {images.map((item, index) => {
          const path =
            item.link.url.includes("https://orcollective.co.uk") &&
            item.link.url.split("https://orcollective.co.uk")[1];
          return (
            <div key={index} className={styles.imageContainer}>
              <Link href={path || item.link.url || "/"}>
                <a>
                  <img
                    className={styles.image}
                    src={item.image.url}
                    alt="New in image"
                  />
                  <div className={styles.imageCredit}>{item.title}</div>
                </a>
              </Link>
            </div>
          );
        })}
      </div>
      <div className={styles.contentContainer}>
        {images.map((item, index) => {
          const path =
            item.link.url.includes("https://orcollective.co.uk") &&
            item.link.url.split("https://orcollective.co.uk")[1];
          return (
            <div key={index} className={styles.imageContainer}>
              <Link href={path || item.link.url || "/"}>
                <a>
                  <img
                    className={styles.image}
                    src={item.image.url}
                    alt="New in image"
                  />
                  <div className={styles.imageCredit}>{item.title}</div>
                </a>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HeroCarousel;
