import React from "react";
import Image from "next/image";
import { RichText } from "prismic-reactjs";

import { useDesktopMediaQuery } from "../../../utils/helpers";
import styles from "./imageLeftTextRightImageBelow.module.css";
import PageSection from "../../section/PageSection";
import ImageLeftTextRightSlice from "./ImageLeftTextRightSlice";
import customImageLoader from "../../../utils/imageloader";

import Props from "./types";

const ImageLeftTextRightImageBelowSlice = ({ slice }: Props) => {
  const isDesktop = useDesktopMediaQuery();
  const imageLeft = slice?.primary.imageLeft;
  const imageBelow = slice?.primary.imageBelow;
  const imageAlignment = slice?.primary.imageBelowAlignment || "left";
  const bodyText = slice?.primary.bodyTextRight;
  const textCredit = slice?.primary.textCredit;

  if (!imageBelow.url) {
    return <ImageLeftTextRightSlice slice={slice} />;
  }

  return (
    <PageSection>
      <div className={styles.sliceContainer}>
        <div className={styles.containerColumn}>
          <div className={styles.image1}>
            {imageLeft && imageLeft.url && (
              <Image
                src={imageLeft.url}
                loading="eager"
                alt="image of baby"
                width={imageLeft.dimensions.width}
                height={imageLeft.dimensions.height}
                layout={isDesktop ? "intrinsic" : "responsive"}
                loader={customImageLoader}
              />
            )}
          </div>
        </div>
        <div className={styles.containerColumn}>
          <div className={styles.textContainer}>
            <div className={styles.bodyText}>
              <RichText render={bodyText} />
            </div>
            <div className={styles.textCredit}>
              <RichText render={textCredit} />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.sliceContainer}>
        <div className={styles.containerColumn}></div>
        <div className={styles.containerColumn}>
          <div
            className={
              imageAlignment === "left" ? styles.image2 : styles.image2Right
            }
          >
            {imageBelow && imageBelow.url && (
              <Image
                src={imageBelow.url}
                alt="image of baby"
                loading="eager"
                width={imageBelow.dimensions.width}
                height={imageBelow.dimensions.height}
                layout={isDesktop ? "intrinsic" : "responsive"}
                loader={customImageLoader}
              />
            )}
          </div>
        </div>
      </div>
    </PageSection>
  );
};

export default ImageLeftTextRightImageBelowSlice;
