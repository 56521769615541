import React, { useEffect, useState, useRef, useContext } from "react";
import Image from "next/image";
import { RichText } from "prismic-reactjs";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from "pure-react-carousel";
import { useRouter } from "next/router";
import { FormikProps, FormikValues } from "formik";

import Props from "./types";

import Section from "../../section/Section";
import Button from "../../button/Button";
import SubscriptionOptionGridCard from "../../subscriptionOption/SubscriptionOptionGridCard";
import StyledForYouGridCard from "../../subscriptionOption/StyledForYouGridCard";
import { useDesktopMediaQuery } from "../../../utils/helpers";
import { useShopContext } from "../../../context/ShopContext";
import { useUser } from "../../../context/UserContext";
import GiftAmountForm from "../../giftAmountForm/GiftAmountForm";
import PostcodeForm from "../../../components/postcodeForm/PostcodeForm";
import PostcodePopup from "../../../components/postcodePopup/PostcodePopup";
import { useAppContext } from "../../../context/AppContext";
import { ModalContext } from "../../../context/ModalContext";

import styles from "./subsciptionGridHorizontal.module.css";

const SubscriptionGridHorizontal = ({ slice }: Props): JSX.Element => {
  const { applySubscriptionOption, subscriptionOption } = useShopContext();
  const { activeOrderSubscriptionPlan } = useUser();
  const customSubmitRef = useRef<FormikProps<FormikValues>>(null);
  const postcodeRef = useRef<FormikProps<FormikValues>>(null);
  const [activeSelection, setActiveSelection] = useState(null); // **Subscriptions
  const [activeGifting, setActiveGifting] = useState(null); // **Gifting
  const [giftSelection, setGiftSelection] = useState<number | null>(null); // **Gifting
  const [errorMessage, setErrorMessage] = useState<string>(null); // **
  const { postcodes, popupImage } = useAppContext();
  const { handleModal } = useContext(ModalContext);

  const isDesktop = useDesktopMediaQuery();
  const router = useRouter();

  const handleSubscriptionSelection = ({ plan, limit, price, stripeId }) => {
    setActiveSelection(stripeId);
    applySubscriptionOption({ plan, limit, price, stripeId });
    // Scrolldown
    // document
    //   .querySelector("#collection-shop")
    //   .scrollIntoView({ behavior: "smooth" });
  };

  const handleSetSelection = ({ price }) => {
    customSubmitRef.current.resetForm();
    handleGiftSelection({ price });
  };

  const handleGiftSelection = ({ price }) => {
    setActiveGifting(price);
    setGiftSelection(Number(price));
  };

  const handleProceedCheckout = ({ giftAmount }) => {
    if (giftSelection || giftAmount > 0) {
      if (!giftSelection) {
        router.push(`/checkout-gifting?price=${Number(giftAmount)}`);
      } else {
        router.push(`/checkout-gifting?price=${Number(giftSelection)}`);
      }
    } else {
      //set message please choose an amount
      setErrorMessage("Please select a gift card or enter custom amount");
    }
  };

  const handleSubmit = () => {
    setErrorMessage("");
    const { postcode } = postcodeRef?.current?.values;
    //! disabled postcode checker for now
    // const accepted = !!postcodes.find(
    //   (code) => postcode.trim().toLowerCase().indexOf(code) > -1
    // );
    // if (postcode && !accepted) {
    //   handleModal({
    //     content: <PostcodePopup accepted={false} popupImage={popupImage} />,
    //     withImage: true,
    //   });
    // } else

    if (!postcode) {
      //set err message to include postcode
      setErrorMessage("Please enter the recipients postcode above");
    } else {
      customSubmitRef?.current?.handleSubmit();
    }
  };

  const unselect = () => {
    setActiveGifting(null);
    setGiftSelection(null);
  };

  useEffect(() => {
    if (subscriptionOption && !activeSelection) {
      setActiveSelection(subscriptionOption.plan);
    }
  }, [subscriptionOption, activeSelection, setActiveSelection]);

  useEffect(() => {
    // sets subscrption option from old active order (on kept item modal redirect)
    if (activeOrderSubscriptionPlan && router.query.set_previous_subscription) {
      const newSub = slice.items.find(
        (itm) => itm.stripeId === activeOrderSubscriptionPlan
      );

      handleSubscriptionSelection({
        plan: newSub.subscriptionOption,
        limit: newSub.optionLimit,
        price: newSub.optionPrice,
        stripeId: newSub.stripeId,
      });

      // switch (activeOrderSubscriptionPlan) {
      //   case "Subscription A":
      //     const item = slice.items[0];
      //     handleSubscriptionSelection({
      //       plan: item.subscriptionOption,
      //       limit: item.optionLimit,
      //       price: item.optionPrice,
      //       stripeId: item.stripeId,
      //     });
      //     break;
      //   case "Subscription B":
      //     const item1 = slice.items[1];
      //     handleSubscriptionSelection({
      //       plan: item1.subscriptionOption,
      //       limit: item1.optionLimit,
      //       price: item1.optionPrice,
      //       stripeId: item1.stripeId,
      //     });
      //     break;
      //   case "Subscription C":
      //     const item2 = slice.items[2];
      //     handleSubscriptionSelection({
      //       plan: item2.subscriptionOption,
      //       limit: item2.optionLimit,
      //       price: item2.optionPrice,
      //       stripeId: item2.stripeId,
      //     });
      //     break;
      // }
    }
  }, [activeOrderSubscriptionPlan, slice.items]);

  // Giftings

  const doc = slice?.primary;
  const title = doc.title;
  const buttonText = doc.buttonText;
  const subscriptionOptions = slice?.items;

  useEffect(() => {
    if (subscriptionOption) {
      setActiveSelection(subscriptionOption.stripeId);
    }
  }, [subscriptionOption]);

  const sliceContent = (
    <>
      {slice?.variation === "subscriptionGridHorizontal" &&
        subscriptionOptions.map((item, index) => {
          const props = {
            letter: item.subscriptionOption,
            title: item.optionTitle,
            description: item.optionBody,
            price: item.optionPrice,
            image: item.optionImage,
            buttonText:
              activeSelection === item.stripeId ? "Selected" : item.buttonText,

            link: item.link,
            limit: item.optionLimit,
            key: index,
            stripeId: item.stripeId,
            onSelect: handleSubscriptionSelection,
            active: activeSelection === item.stripeId,
          };
          return (
            <>
              {true ? (
                <SubscriptionOptionGridCard {...props} />
              ) : (
                <Slide innerClassName={styles.slide} key={index} index={index}>
                  <SubscriptionOptionGridCard {...props} />
                </Slide>
              )}
            </>
          );
        })}
      {slice?.variation === "subscriptionGridGifting" &&
        subscriptionOptions.map((item, index) => {
          const props = {
            letter: item.subscriptionOption,
            title: item.optionTitle,
            description: item.optionBody,
            price: item.optionPrice,
            image: item.optionImage,
            buttonText:
              activeGifting === item.optionPrice ? "Selected" : item.buttonText,
            gifting: true,
            link: item.link,
            key: index,
            stripeId: item.stripeId,
            onSelect: handleSetSelection,
            active: activeGifting === item.optionPrice,
          };
          return (
            <>
              {true ? (
                <SubscriptionOptionGridCard {...props} />
              ) : (
                <Slide innerClassName={styles.slide} key={index} index={index}>
                  <SubscriptionOptionGridCard {...props} />
                </Slide>
              )}
            </>
          );
        })}
      {slice?.variation === "styledForYou" &&
        subscriptionOptions.map((item, index) => {
          return (
            <StyledForYouGridCard
              letter={item.subscriptionOption}
              title={item.optionTitle}
              description={item.optionBody}
              price={item.optionPrice}
              image={[
                item.image1 && item.image1,
                item.image2 && item.image2,
                item.image3 && item.image3,
                item.image4 && item.image4,
              ]}
              buttonText={item.buttonText}
              link={item.link}
              key={index}
            />
          );
        })}
    </>
  );

  return (
    <Section bgColor="var(--colorWestar)" disableTabletPadding>
      <div className={styles.container}>
        <div className={styles.title}>
          <RichText render={title} />
        </div>
        <div className={styles.optionsContainer}>
          {true ? (
            sliceContent
          ) : (
            <CarouselProvider
              naturalSlideWidth={100}
              isIntrinsicHeight
              naturalSlideHeight={100}
              totalSlides={subscriptionOptions.length}
              className={styles.carouselProvider}
              infinite
            >
              <Slider>{sliceContent}</Slider>
              <DotGroup className={styles.dots} />
            </CarouselProvider>
          )}
        </div>
        {slice?.variation === "subscriptionGridGifting" && (
          <>
            <div className={styles.checkout}>
              <div className={styles.checkoutRow}>
                <div className={styles.custom}>Custom amount</div>
                <GiftAmountForm
                  handleCheckout={handleProceedCheckout}
                  buttonText={buttonText}
                  onInputFocus={unselect}
                  formSubmitRef={customSubmitRef}
                />
              </div>

              <div className={styles.checkoutColumn}>
                <div className={styles.messageTitleNoPadding}>
                  {"Recipient's postcode"}
                </div>
                <div className={styles.subtitle}>
                  We are expanding and don’t yet deliver to all postcodes,
                  please check if your recipient’s postcode is currently in our
                  delivery zone.
                </div>
                <div className={styles.subtitle}>
                  You can also drop us an email on hello@orcollective.co.uk to
                  find out more about expansion plans.
                </div>
                <PostcodeForm formSubmitRef={postcodeRef} />
              </div>
            </div>
            <div className={styles.buttonGifting}>
              <Button type="submit" onClick={handleSubmit} text={buttonText} />
            </div>

            <div className={styles.errorMessage}>{errorMessage}</div>
          </>
        )}
        {/* <div className={styles.button}>
          <Button href="/styled-for-you" text={buttonText} />
        </div> */}
      </div>
    </Section>
  );
};

export default SubscriptionGridHorizontal;
