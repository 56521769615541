import React from "react";
import { RichText } from "prismic-reactjs";

import Props from "./types";

import Section from "../../section/Section";
import SubscriptionOption from "../../subscriptionOption/SubscriptionOption";
import SubscriptionGridHorizontal from "./SubscriptionGridHorizontal";
import SwitchSubscription from "./SwitchSubscription";

import styles from "./subscriptionInfo.module.css";

const SubscriptionInfoSlice = ({ slice }: Props): JSX.Element => {
  if (
    slice?.variation === "subscriptionGridHorizontal" ||
    slice?.variation === "styledForYou" ||
    slice?.variation === "subscriptionGridGifting"
  ) {
    return <SubscriptionGridHorizontal slice={slice} />;
  }

  if (slice?.variation === "switchSubscription") {
    return <SwitchSubscription slice={slice} />;
  }

  // if (slice?.variation === "styled-for-you") {
  //   return <SubscriptionGridHorizontal slice={slice} />;
  // }

  const doc = slice?.primary;
  const title1 = doc.howItWorksTitle[0].text;
  const bodyText = doc.howItWorksText;

  const title2 = doc.subscriptionTitle[0].text;
  const buttonText = doc.subscribeButtonText;
  const subscriptionOptions = slice.items;

  return (
    <Section bgColor="var(--colorWestar)">
      <div className={styles.subscriptionContainer}>
        <div className={styles.title}>{title1}</div>
        <div className={styles.contentContainer}>
          <div className={styles.bodyText}>
            <RichText render={bodyText} />
          </div>
        </div>
        {/* {isDesktop && (
          <div className={styles.button}>
            <Button text={buttonText} />
          </div>
        )} */}
      </div>
      <div className={styles.subscriptionContainer}>
        <div className={styles.title}>{title2}</div>
        {subscriptionOptions.map((item, index) => {
          return (
            <SubscriptionOption
              key={index}
              letter={item.subscriptionOption}
              title={item.optionTitle}
              description={item.optionBody}
              price={item.optionPrice}
              limit={item.optionLimit}
              stripeId={item.stripeId}
            />
          );
        })}
        {/* {!isDesktop && (
          <div className={styles.button}>
            <Button text={buttonText} />
          </div>
        )} */}
      </div>
    </Section>
  );
};

export default SubscriptionInfoSlice;
