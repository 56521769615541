import React from "react";
import Image from "next/image";
import Link from "next/link";
import { RichText } from "prismic-reactjs";

import SubscriptionOption from "../../subscriptionOption/SubscriptionOption";
import { useDesktopMediaQuery } from "../../../utils/helpers";
import PageSection from "../../section/PageSection";
import customImageLoader from "../../../utils/imageloader";

import styles from "./imageLeftProcessRight.module.css";
import Props from "./types";
import Button from "../../button/Button";

const ImageLeftProcessRightSlice = ({ slice }: Props) => {
  const image = slice?.primary?.image;
  const cta = slice?.primary?.cta;
  const cta_text = slice?.primary?.ctatext;
  const items = slice?.items;

  return (
    <PageSection>
      <div className={styles.sliceContainer}>
        <div className={styles.containerColumn}>
          <div className={styles.image}>
            {image.url && (
              <Image
                src={image.url}
                width={image.dimensions.width}
                height={image.dimensions.height}
                layout={"responsive"}
                alt="image"
                loading="eager"
              />
            )}
          </div>
        </div>
        <div className={styles.processContainer}>
          <div className={styles.processColumn}>
            {items.map((item, index) => {
              // if (index < 2) {
              return (
                <div className={styles.itemContainer} key={index}>
                  <div className={styles.icon}>
                    <Image
                      src={item.icon.url}
                      height={item.icon.dimensions.height}
                      width={item.icon.dimensions.width}
                      alt="Display image"
                      loading="eager"
                      layout="responsive"
                      loader={customImageLoader}
                    />
                  </div>
                  <div className={styles.itemTitle}>
                    <RichText render={item.title} />
                  </div>
                  <div className={styles.itemBodyText}>
                    <RichText render={item.bodyText} />
                  </div>
                </div>
              );
              // }
            })}
          </div>
          {/* <div className={styles.processColumn}>
            {items.map((item, index) => {
              if (index >= 2) {
                return (
                  <div className={styles.itemContainer} key={index}>
                    <div className={styles.icon}>
                      <Image
                        src={item.icon.url}
                        height={item.icon.dimensions.height}
                        width={item.icon.dimensions.width}
                        alt="Display image"
                        layout="responsive"
                        loading="eager"
                      />
                    </div>
                    <div className={styles.itemTitle}>
                      <RichText render={item.title} />
                    </div>
                    <div className={styles.itemBodyText}>
                      <RichText render={item.bodyText} />
                    </div>
                  </div>
                );
              }
            })}
          </div> */}
        </div>
      </div>
      {cta && (
        <div className={styles.ctaContainer}>
          <Button
            text={cta_text}
            className={styles.cta}
            href={cta.url || "/" + cta.uid}
          />
        </div>
      )}
    </PageSection>
  );
};

export default ImageLeftProcessRightSlice;
