import React from "react";
import classNames from "classnames";

import Props from "./types";
import { useShopContext } from "../../context/ShopContext";
import InternalLink from "../../components/InternalLink";

import styles from "./subscriptionOption.module.css";
const SubscriptionOption = ({
  letter,
  title,
  description,
  price,
  limit,
  stripeId,
}: Props): JSX.Element => {
  const { applySubscriptionOption, subscriptionOption } = useShopContext();

  const handleSubscribe = () => {
    applySubscriptionOption({
      plan: letter,
      limit: limit,
      price: price,
      stripeId: stripeId,
    });
  };

  // TODO - update links for subscription options depending on collection, add a prop?
  return (
    <div onClick={handleSubscribe}>
      <InternalLink href="/collection#collection-shop">
        <a>
          <div
            className={classNames(styles.container, {
              [styles.optionActive]: subscriptionOption?.stripeId === stripeId,
            })}
          >
            <div className={styles.innerContainer}>
              <div className={styles.letter}>{letter}</div>
              <div>
                <div className={styles.textBold}>{title}</div>
                <div className={styles.text}>{description}</div>
                <div className={styles.textBold}>{price}</div>
              </div>
            </div>
          </div>
        </a>
      </InternalLink>
    </div>
  );
};

export default SubscriptionOption;
