// This file is generated by Slice Machine. Update with care!

import AboutUs from './AboutUs';
import AboutUsPage from './AboutUsPage';
import Delivery from './Delivery';
import HeroCarousel from './HeroCarousel';
import ImageLeftProcessRight from './ImageLeftProcessRight';
import ImageLeftTextRightImageBelow from './ImageLeftTextRightImageBelow';
import NavigationItem from './NavigationItem';
import NewIn from './NewIn';
import Newsletter from './Newsletter';
import Popup from './Popup';
import StyleValues from './StyleValues';
import StyleValuesBreakdown from './StyleValuesBreakdown';
import SubscriptionInfo from './SubscriptionInfo';
import TermsAndConditions from './TermsAndConditions';
import Test from './Test';

export {
	AboutUs,
	AboutUsPage,
	Delivery,
	HeroCarousel,
	ImageLeftProcessRight,
	ImageLeftTextRightImageBelow,
	NavigationItem,
	NewIn,
	Newsletter,
	Popup,
	StyleValues,
	StyleValuesBreakdown,
	SubscriptionInfo,
	TermsAndConditions,
	Test,
};

export const components = {
	about_us: AboutUs,
	about_us_page: AboutUsPage,
	delivery: Delivery,
	hero_carousel: HeroCarousel,
	image_left_process_right: ImageLeftProcessRight,
	image_left_text_right_image_below: ImageLeftTextRightImageBelow,
	navigation_item: NavigationItem,
	new_in: NewIn,
	newsletter: Newsletter,
	popup: Popup,
	style_values: StyleValues,
	style_values_breakdown: StyleValuesBreakdown,
	subscription_info: SubscriptionInfo,
	terms_and_conditions: TermsAndConditions,
	test: Test,
};
