import React from "react";
import Image from "next/image";
import { RichText } from "prismic-reactjs";
import { useRouter } from "next/router";

import { useDesktopMediaQuery } from "../../../utils/helpers";
import styles from "./imageLeftTextRightImageBelow.module.css";
import PageSection from "../../section/PageSection";
import customImageLoader from "../../../utils/imageloader";

import Props from "./types";

const ImageLeftTextRightSlice = ({
  slice: {
    primary: { imageLeft, bodyTextRight },
  },
}: Props) => {
  const router = useRouter();
  const orderSuccess = router?.query?.order_complete;
  const orderFailed = router?.query?.order_failed;
  const isDesktop = useDesktopMediaQuery();

  // !imageBelow &&
  return (
    <PageSection>
      {orderSuccess && (
        <div className={styles.successMessage}>
          Checkout successful, thank you for your order!
        </div>
      )}
      {orderFailed && (
        <div className={styles.successMessage}>
          Checkout failed, please try again.
        </div>
      )}
      <div className={styles.sliceContainerCentered}>
        <div className={styles.containerColumnCenter}>
          <div className={styles.image1}>
            {imageLeft && imageLeft.url && (
              <Image
                src={imageLeft.url}
                loading="eager"
                alt="image of baby"
                width={imageLeft.dimensions.width}
                height={imageLeft.dimensions.height}
                layout={isDesktop ? "intrinsic" : "responsive"}
                loader={customImageLoader}
              />
            )}
          </div>
        </div>
        <div className={styles.containerColumnCenter}>
          <div className={styles.textContainer}>
            <div className={styles.bodyText}>
              <RichText render={bodyTextRight} />
            </div>
          </div>
        </div>
      </div>
    </PageSection>
  );
};

export default ImageLeftTextRightSlice;
