import React, { useContext, useState } from "react";
import Image from "next/image";
import { useRouter } from "next/router";

import Props from "./types";
import ICONS from "../constants/icons";
import FORMS from "../constants/forms";
import SimpleInput from "../input/SimpleInput";
import PostcodeForm from "../postcodeForm/PostcodeForm";
import { ModalContext } from "../../context/ModalContext";
import Button from "../button/Button";
import { addSubscriber } from "../../modules/@klaviyo";
import { KlaviyoListCodes } from "../../commonTypes";

import styles from "./postcodePopup.module.css";

const PostcodePopup = ({
  accepted,
  popupImage,
  check,
  newsletter,
}: Props): JSX.Element => {
  const [success, setSuccess] = useState(null);
  const { handleModal } = useContext(ModalContext);

  const router = useRouter();
  const addingToCart = router.asPath !== "/";

  // Modal title
  const standardTitle = "Sorry, not yet.";
  const successTitle = "We're ready for you!";

  //postcode check
  const checkText =
    "We are expanding and don't yet cover all areas, check if we deliver to you.";
  const checkButtonText = "Check";

  //postcode accepted
  const acceptedText =
    "We are taking on new members in your postcode area, click below to get started.";
  const acceptedButtonText = "Get started";

  //postcode accepted + adding item to cart
  const acceptedTextCart =
    "We are delivering to your postcode, please click below to continue shopping!";
  const acceptedButtonTextCart = "Continue shopping";

  //postcode declined
  const declinedText =
    "We aren’t taking on new members in your area yet, please add your details to be added to the waitlist.";
  const declinedButtonText = "Notify me";

  //! Prelaunch text
  const newsletterText =
    "Subscribe to OR emails to claim your discount and be the first to know about new collections, exclusive offers and other news. Terms and conditions apply.";
  const newsletterTitle = "Newsletter";

  const newsletterButton = "Subscribe";

  const declinedButton = () => {
    return (
      <div className={styles.buttonDeclined}>
        <Button
          type="submit"
          disabled={success}
          text={success ? "Email subscribed!" : declinedButtonText}
        />
      </div>
    );
  };

  const handleSubmit = (values) => {
    addSubscriber(values.email, {
      ...values,
      list: newsletter
        ? KlaviyoListCodes.NEWSLETTER
        : KlaviyoListCodes.WAITLIST, //customer newsletter list : waitlist // Delivery Waiting List id
    }).then(() => setSuccess(true));
  };

  const formData = {
    ...FORMS.POSTCODE_NEWSLETTER,
    handleSubmit: handleSubmit,
    submitButton: declinedButton,
  };

  const newsletterFormData = {
    ...FORMS.NEWSLETTER,
    handleSubmit: handleSubmit,
    submitButton: declinedButton,
  };

  return (
    <div className={styles.container}>
      <div className={styles.modalLeft}>
        <div className={styles.description}>
          <div className={styles.modalTitle}>
            {newsletter
              ? newsletterTitle
              : accepted
              ? successTitle
              : standardTitle}
          </div>
          <div className={styles.modalBody}>
            {/*conditional rendering of messages depending on postcode results*/}
            {newsletter && newsletterText}
            {accepted && !check && !addingToCart && acceptedText}
            {accepted && !check && addingToCart && acceptedTextCart}
            {!accepted && !check && !newsletter && declinedText}
            {check && checkText}
          </div>
        </div>
        {/*conditional rendering of buttons + actions depending on postcode results*/}
        {check && (
          <div className={styles.newsletterForm}>
            <PostcodeForm
              buttonText={newsletter ? newsletterButton : checkButtonText}
              buttonClassName={styles.button}
            />
          </div>
        )}
        {accepted && !check && !addingToCart && (
          <div className={styles.button}>
            <Button
              onClick={() => {
                router.push("/collection");
                handleModal({ content: false, withImage: false });
              }}
              text={acceptedButtonText}
            />
          </div>
        )}
        {accepted && !check && addingToCart && (
          <div className={styles.button}>
            <Button
              onClick={() => handleModal({ content: false, withImage: false })}
              text={acceptedButtonTextCart}
            />
          </div>
        )}
        {!accepted && !check && (
          <div className={styles.newsletterForm}>
            <SimpleInput data={newsletter ? newsletterFormData : formData} />
          </div>
        )}
        <ICONS.ORLOGO size={100} />
      </div>
      <div
        className={styles.modalRight}
        style={{ backgroundImage: `url(${popupImage?.url})` }}
      >
        {/* <Image
          src={popupImage.url}
          width={popupImage?.dimensions.width}
          height={popupImage?.dimensions.height}
          alt="image of child"
          layout="intrinsic"
        /> */}
      </div>
    </div>
  );
};

export default PostcodePopup;
