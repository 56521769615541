import React from "react";
import Image from "next/image";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from "pure-react-carousel";

import Props from "./types";

import ICONS from "../../constants/icons";
import Section from "../../section/Section";
import InternalLink from "../../InternalLink";
import { useDesktopMediaQuery } from "../../../utils/helpers";
import customImageLoader from "../../../utils/imageloader";

import "pure-react-carousel/dist/react-carousel.es.css";
import styles from "./styleValues.module.css";

const StyleValuesSlice = ({ slice }: Props): JSX.Element => {
  const isDesktop = useDesktopMediaQuery();
  const title = slice?.primary.title[0].text;
  const images = slice.items;

  return (
    <>
      <Section
        bgColor="var(--colorWhite)"
        minHeight="100px !important"
        disableBottomPadding={true}
        width="100%"
      >
        <div className={styles.styleValuesContainer}>
          <div className={styles.headerContainer}>
            <div className={styles.title}>{title}</div>
          </div>
        </div>
      </Section>
      <div className={styles.contentContainer}>
        <CarouselProvider
          naturalSlideWidth={!isDesktop ? 100 : 16}
          naturalSlideHeight={!isDesktop ? 100 : 9}
          totalSlides={images.length}
          className={styles.carouselProvider}
          infinite
        >
          <Slider>
            {images.map((item, index) => {
              return (
                <Slide innerClassName={styles.slide} key={index} index={index}>
                  <InternalLink href={item.link || "/"}>
                    <a>
                      <div className={styles.imageContainer}>
                        <div className={styles.bodyText}>
                          {item.styleBodyText[0].text}
                        </div>
                        <Image
                          src={item.image.url}
                          height={item.image.dimensions.height}
                          width={item.image.dimensions.width}
                          alt="Display image"
                          layout="intrinsic"
                          loading="eager"
                          loader={customImageLoader}
                        />
                        <div className={styles.overlayTextContainer}>
                          <div className={styles.overlayText}>
                            {item.styleTitle}
                          </div>
                        </div>
                      </div>
                    </a>
                  </InternalLink>
                </Slide>
              );
            })}
          </Slider>
          <DotGroup className={styles.dots} />
          <ButtonBack className={styles.backButton}>
            <ICONS.ARROW />
          </ButtonBack>
          <ButtonNext className={styles.forwardButton}>
            <ICONS.ARROW />
          </ButtonNext>
        </CarouselProvider>
      </div>
    </>
  );
};

export default StyleValuesSlice;
