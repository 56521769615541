import React from "react";
import Link from "next/link";

import Props from "./types";
import Section from "../../section/Section";
import Button from "../../button/Button";
import InternalLink from "../../InternalLink";

import styles from "./aboutUs.module.css";

const AboutUsSlice = ({ children, slice }: Props): JSX.Element => {
  const title = slice?.primary.title[0].text;
  const bodyText = slice?.primary.bodyText[0].text;
  const buttonText = slice?.primary.buttonText;
  const buttonLink = slice?.primary.buttonLink.uid;

  return (
    <Section bgColor="var(--colorWestar)" minHeight="500px">
      <div className={styles.aboutUsContainer}>
        <div>
          <div className={styles.title}>{title}</div>
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.bodyText}>{bodyText}</div>
        </div>
        <div className={styles.button}>
          <InternalLink href={buttonLink}>
            <a>
              <Button text={buttonText} />
            </a>
          </InternalLink>
        </div>
      </div>
    </Section>
  );
};

export default AboutUsSlice;
