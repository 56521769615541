import React from "react";
import Image from "next/image";
import Link from "next/link";

import Props from "./types";

import Button from "../../button/Button";
import Section from "../../section/Section";
import customImageLoader from "../../../utils/imageloader";

import styles from "./newIn.module.css";

const NewInSlice = ({ slice }: Props): JSX.Element => {
  const title = slice?.primary.title[0].text;
  const buttonText = slice?.primary.buttonText;
  const buttonLink =
    slice?.primary?.buttonLink?.uid || slice?.primary?.buttonLink?.url;
  const images = slice.items;

  const buttonPath =
    buttonLink.includes("https://orcollective.co.uk") &&
    buttonLink.split("https://orcollective.co.uk")[1];

  return (
    <Section bgColor="var(--colorWhite)">
      <div className={styles.newInContainer}>
        <div className={styles.title}>{title}</div>
        <div className={styles.contentContainer}>
          {images.map((image, index: number) => {
            const path =
              image.path.includes("https://orcollective.co.uk") &&
              image.path.split("https://orcollective.co.uk")[1];

            return (
              <div key={index} className={styles.imageItem}>
                <Link href={path || image.path} passHref>
                  <a>
                    <Image
                      src={image.image.url}
                      height={image.image.dimensions.height}
                      width={image.image.dimensions.width}
                      alt="Display image"
                      layout="intrinsic"
                      loading="eager"
                      loader={customImageLoader}
                    />
                  </a>
                </Link>
                <div className={styles.imageCredit}>{image.imageTitle}</div>
              </div>
            );
          })}
        </div>
        <div className={styles.button}>
          <Link href={buttonPath || ""}>
            <a>
              <Button text={buttonText} />
            </a>
          </Link>
        </div>
      </div>
    </Section>
  );
};

export default NewInSlice;
